import { Mapper } from "../../../../../harmony/core";
import { PageDto } from "../../data/page.parser";
import { Page } from "../models/page";

export class PageMapper<From, To> implements Mapper<PageDto<From>, Page<To>> {
  constructor(private readonly mapper: Mapper<From, To>) {}

  public map(from: PageDto<From>): Page<To> {
    return new Page(
      from.pageNumber,
      from.size,
      from.totalSize,
      from.entries ? from.entries.map((entry) => this.mapper.map(entry)) : [],
    );
  }
}
