import { z } from "zod";

export const createPageParser = <T extends z.ZodTypeAny>(entry: T) =>
  z
    .object({
      pageNumber: z.number().min(1),
      size: z.number().min(1),
      totalSize: z.number().min(0),
      entries: z.array(entry).optional(),
    })
    .strict();

export interface PageDto<T> {
  pageNumber: number;
  size: number;
  totalSize: number;
  entries?: T[];
}
