<div class="reload">
  <div class="reload-blank"></div>
  <div class="reload-content">
    <div class="reload-content-img">
      <img src="/assets/images/reload-page-man.png" />
    </div>
    <div class="reload-content-txt">
      <div
        class="reload-content-subtitle"
        [innerHTML]="textKey | translate"
      ></div>
      <div (click)="action.emit()" class="btn btn--primary">
        {{ buttonKey | translate }}
      </div>
    </div>
  </div>
</div>
