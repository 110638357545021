import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-reload",
  templateUrl: "./reload.component.html",
  styleUrl: "./reload.component.scss",
  standalone: true,
  imports: [TranslateModule],
})
export class ReloadComponent {
  @Input() public readonly textKey = "shared.errorPerformingTheAction";
  @Input() public readonly buttonKey = "shared.retry";

  @Output() public readonly action = new EventEmitter<void>();
}
